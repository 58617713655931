import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function Selector(props) {
  const [{ open = false } = props] = React.useState();

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {props.title && (
          <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        )}
        <DialogContent>
          {props.data.map((item, index) => {
            console.log("UNIT DATA", item);
            if (props.unit) {
              return (
                <div
                  onClick={() =>
                    props.onSelect({ label: item.title, value: item._id })
                  }
                  key={index}
                  style={{
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    marginBotton: 10,
                    cursor: "pointer",
                    padding: 10,
                  }}
                >
                  {item.title}
                </div>
              );
            } else {
              return (
                <div
                  onClick={() => props.onSelect({ label: item, value: item })}
                  key={index}
                  style={{
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    marginBotton: 10,
                    cursor: "pointer",
                    padding: 10,
                  }}
                >
                  {item}
                </div>
              );
            }
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
}
